
import React from 'react'
import './Getstarted.css'
const Getstarted = () => {
  return (
    <section className='g-wrapper'>
        <div className='g-container'>
            <div className='inner-container'>
                <span className='firsttext'>Get Started with Homyz</span>
                <br/>
                <span>
                    Subscribe and find super attractive price quotes from us
                    <br/>
                    Find your residence soon
                </span>
            </div>
        </div>
    </section>
  )
}

export default Getstarted
