import React from 'react'
import'./Contact.css'
import emailjs from '@emailjs/browser';
import {useRef} from"react"
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_obssz87', 'template_086mo6m', form.current, 'K5V1rEsytNXsldoU4')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  return (
   <section classNmae="c-wrapper">
    <div className="container">
      <div className="left_side" >
       < span className="orange"><h1>Our Contact</h1></span>
       <span className="primary">Easy to Contact us</span>
       <br/>
       <span className="second">We always ready to help by providijng the best services for you.
       <br/><p className='secondpara' >We beleive a good blace to live can make your life better</p></span>
         <div className='contact'>
          <div className='contactdetial'>
            <h4 className='num'>Call Me-9389354</h4>
            <h4 className='shift'>Email Me-vivekchaudhary45500@gmail.com</h4>
          </div>
          <div className='contactform'>
             <h2 className='text-center'>Contact Us</h2>
             <form ref={form} onSubmit={sendEmail}className='form-control'>
              <input className='input' type ="text" placeholder='Full Name'
              name='user_name' required/>
              <input className='input' type ="email" placeholder='Email'
              name='user_email' required/>
              <input className='input' type ="text" placeholder='Subject'
              name='subject' required/>
              <textarea className='input' name='message'
              cols="30" rows="10"></textarea>
              <button className='btn'>Send Message</button>
             </form>
          </div>
         </div>
        </div> 
        <div className="right_side">
          <div className="image-container image-cont">
            <img src="./contact.jpg"alt=""/>
          </div>
        </div>
    </div>
   </section>
  ) 
}

export default Contact
