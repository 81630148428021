import React from 'react'
import './Header.css' 
const Header = () => {
  return (
    <div>
  <section className="h-wrapper">
    <div className=" flexCenter padding innerwidth h-container">
        <img src="./img1.png"alt="img" width={50}/>
       
     <div className=" flexCenter h-menu menu-h ">
        <a href="">Residencies</a>
        <a href="">Our Value</a>
        <a href="">Contact Us</a>
        <a href="">Get Started</a>
        <button className="button">
            <a href="">Contact</a> 
        </button>
    </div>
    </div>
  </section>
    </div>
  )
}

export default Header
