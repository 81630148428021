import React from 'react'
import './Mycmp.css'
const Mycmp = () => {
  return (
    <section className="c-wrapper">
    <div className = "paddings innerwidth flexcenter c-container mycmp">
     <img src='prologis.png' alt=''/>
     <img src='tower.png' alt=''/>
     <img src='equinix.png' alt=''/>
     <img src='realty.png' alt=''/>
    </div>
    </section>
  )
}

export default Mycmp
