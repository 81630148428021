
import './App.css';
import Header from './components/header/Header'
import Hero from './components/Hero/Hero'
import './App.css'
import Mycmp from'./components/mycomp/Mycmp'
import Value from './components/Value/Value';
import Contact from './components/contact/Contact';
import Getstarted from './components/Getstarted/Getstarted';
function App() {
  return (
    <div className="App">
    <Header/>
    <Hero/>
    <Mycmp/> 
    <Value/>
    <Contact/>
    <Getstarted/>
    </div>
  );
}

export default App;
