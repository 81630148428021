import React from 'react';
import  "./Hero.css";
import CountUp from 'react-countup';
const Hero = () => {
  return (
    <div>
      <section className="hero-wrapper">
        <div className ="paddings innerwidth hero-container flexCenter">
          <div className="flexcolstart hero-left">
            <div className ="hero-title">
                <div className="orange-circle"/>
                <h1 >
                   Discover<br/>
                    Most Suitable<br/>
                     Property
                   
                </h1>
            </div>
            <div className = " flexcolstart hero-des">
                <span >Find a veriety of properties that suit you very easilty</span>
                <span>Forget all difficulties in finding a residence for you</span>
        </div>
            <div className="search-bar">
              <input type ="text" placeholder=''></input>
              <img src="img3.png" alt=""/>
              <button className="search-button">
                <a href=''>Search</a>
              </button>
               </div>
               <div className="flexCenter stats">
                <div className ="flexcolstart stat s1">
                    <span>
                      <CountUp start={8800} end={9000} duration ={4}/>
                      <span>+</span>
                    </span>
                  
                    <span className="secondary-text">
                     Premium Products 
                    </span>
                   
                </div>
                <div className ="flexcolstart stat s2">
                    <span>
                      <CountUp start={1900} end={2000} duration ={4}/>
                      <span>+</span>
                    </span>
                  
                    <span className='secondary-text'>
                     Happy Customer
                    </span>
                   
                </div>
                <div className ="flexcolstart stat s3">
                    <span>
                      <CountUp start={18} end={28} duration ={4}/>
                      <span>+</span>
                    </span>
                 
                    <span className=' secondary-text'>
                    Awards Winning
                    </span>
                   
                </div>
               </div>
            </div>  
            <div className=" flexCenter hero-right">
            <div className ="image-container img11">
                <img src="img2.png.jpg" alt=""/>
            </div>
            </div>
        </div>
      </section> 
    </div>
  )
}

export default Hero;
