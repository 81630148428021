import React, { useState } from 'react'
import{
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion'
import "react-accessible-accordion/dist/fancy-example.css";
import {MdOutlineArrowDropDown} from 'react-icons/md'
import './Value.css'
import data from'../utils/accordion'
const Value = () => {
    const[className,setClassName]=useState(null)
  return (
    <div>
   <section className="v-wrapper">
    <div className="paddings innerwidth flexCenter v-container v-viv">
        <div className="v-left">
            <div className="image-container img12">
                <img src="./value.png" alt=""/>
            </div>
        </div>
        <div className="flexColStart v-right">
            <span className="orangeText"><h1>Our Value</h1>
            </span>
           
            <span className="primaryTextt"> Value We Give to You
            <br/></span>
            <span className="secondaryText">
                We always ready to help by providing the best services for you.
                <br/>
               <p className="backside">We beleive a good blace to live can make your life better</p>
            </span>
            <Accordion
            className='accordion'
            allowMultipleExpanded={false}
            preExpanded={[0]}
             >
         {data.map((item,i)=>
         {   
            return(
           <AccordionItem className={'accordionitem ${className}'}key={i} uuid={i}>
             <AccordionItemHeading>
                <AccordionItemButton>
                    <AccordionItemState>
                     {({expanded})=>expanded?setClassName("expanded"):setClassName("collapsed")}   
                    </AccordionItemState>
                    <div className ="flexCenter icon">
                        {item.icon}
                    </div>
                    <span className="primaryText">
                        {item.heading}
                    </span>
                    <div className="flexCenter icon">
                        <MdOutlineArrowDropDown size={20}/>
                    </div>
                </AccordionItemButton>
             </AccordionItemHeading>
             <AccordionItemPanel>
              <p className="secondaryText secondarytxt">{item.detail}</p> 
             </AccordionItemPanel>
           </AccordionItem>);
 })}
           </Accordion>
        </div>
    </div>
   </section>
    </div>
  )
}

export default Value
